import '.././../../externals/funnelback.autocompletion-2.6.0.js';


(function () {
    'use strict';

    $(function() {
      let currentUrl = window.location.href.split('?')[0];
      let fbAutocompleteURL = "https://qh-search.squiz.cloud/s/resources-global/js/funnelback.autocompletion-2.6.0.js";
      // vars for structuring services/service locations for geolocation autocomplete
      var fbUrl = "https://qh-search.squiz.cloud/s/search.html";
      var fbCollection = "qld-health-core-service-finder__service-locations";
      var fbProfile = "auto-completion";
      var fbForm = "qc";
      var fbNumRanks = 4;
      const locationData = QH.utils.getCookie('qh_user_location') ? JSON.parse(QH.utils.getCookie('qh_user_location')) : {'latitude': '', 'longitude': ''};
      window.Bloodhound = require('corejs-typeahead/dist/bloodhound.js');
      //require("handlebars"); 
      // The above was overriding the Handlebars we already include via scripts.html (including the helpers)
        
        

      $('.qhealth__service-finder input.query').autocompletion({
        datasets: {
          organic: {
            collection: 'qld-health-core-service-finder__meta',
            profile   : '_default',
            program   : 'https://qh-search.squiz.cloud/s/suggest.json',
            show      : 4,
            template: {
                header: $('<h4>').text('Services').addClass('tt-category'),
                suggestion: $('<div class="qhealth__search__autocomplete__suggestion">').text('{{label}}'),
                notFound: $('<div class="qhealth__search__autocomplete__no-suggestions">').html('<em>No suggestions found</em>'),
                footer: [
                  `<div class="more-results">`,
                    `<a href="${currentUrl}?collection=qld-health-core-service-finder__meta" class="qhealth__btn qhealth__btn--primary qhealth__search__autocomplete__see-more">See more <span class="sr-only">results for your query within services</span></a>`,
                  `<div>`
                ].join('\n')
            }
          },
          serviceLocations: {
            collection: 'qld-health-core-service-finder__service-locations',
            profile   : '_default',
            program   : 'https://qh-search.squiz.cloud/s/suggest.json',
            classNames: {
              empty   : 'no-results'
            },
            show      : 4,
            template: {
                header: $('<h4>').text('Hospitals and health centres').addClass('tt-category'),
                suggestion: $('<div class="qhealth__search__autocomplete__suggestion">').text('{{label}}'),
                notFound: $('<div class="qhealth__search__autocomplete__no-suggestions">').html('<em>No suggestions found</em>'),
                footer: [
                  `<div class="more-results">`,
                    `<a href="${currentUrl}?collection=qld-health-core-service-finder__service-locations" class="qhealth__btn qhealth__btn--primary qhealth__search__autocomplete__see-more">See more <span class="sr-only">results for your query within services</span></a>`,
                  `<div>`
                ].join('\n')
            }
          },
          geoLocations: {}
        },
        length: 3,
        empty: ""
      });
    
      $(document).on('input focus', '.qhealth__service-finder input.query', QH.utils.debounce(function() {
        let $this = $(this);
        let query = $this.val();
        let url = `${fbUrl}?collection=${fbCollection}&profile=${fbProfile}&form=${fbForm}&num_ranks=${fbNumRanks}&query=${query}`;
        let categoryName = 'Near (location)';
        // let $ttMenuInit = $('.tt-menu').append('<div role="presentation" class="tt-dataset tt-dataset-geoLocations"></div>');
        // if($('.tt-dataset-geoLocations').length <= 0) {
        //   $('.tt-menu').append('<div role="presentation" class="tt-dataset tt-dataset-geoLocations"></div>');
        // }

        let $el = $('.tt-dataset-geoLocations');


        if($this.val().length >= 3) {
            $.ajax({
              url: url
            }).done(function(data) {

              let locationResults = JSON.parse(data);

              $el.empty();

              $el.append(`<h4 class="tt-category">${categoryName}</h4>`);

              if(locationResults.length > 0) {


                for (const result of locationResults) {

                  $el.append(`
                    <div class="qhealth__search__autocomplete__suggestion tt-suggestion tt-selectable">
                      <a href="${currentUrl}?query=${result.disp}&origin=${locationData.latitude},${locationData.longitude}"><strong class="tt-highlight">${result.disp}</strong></a>
                    </div>
                  `)
                } 

              } else {
                $el.append('<div class="qhealth__search__autocomplete__no-suggestions">No suggestions found</div>');
              }

              $el.append(`<div class="more-results">` +
                `<a href="${currentUrl}?collection=qld-health-core-service-finder__meta&origin=${locationData.latitude},${locationData.longitude}" class="qhealth__btn qhealth__btn--primary qhealth__search__autocomplete__see-more">See more <span class="sr-only">results for your query within services</span></a>` +
              `<div>`);

            });

        }
      }, 400));


      
      $(document).on('click', '.qhealth__search__autocomplete__see-more', function() {
        let $this = $(this);
        let queryInput = $this.closest('.twitter-typeahead').find('#query').val();

        $this.attr('href', $this.attr('href') + `&query=${queryInput}`);
      });
    });
}());