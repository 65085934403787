(function () {
    'use strict';

    /**
     * @module facilityMap
     */
    var facilityMap = {};

    /**
     * Initialise all embedded facility maps on a particular page
     * 
     * @memberof module:facilityMap
     */
    facilityMap.init = function() {
        var mapEmbedFacilities = QH.utils.listToArray(document.querySelectorAll('.js-map-embed-facility'));

        mapEmbedFacilities.forEach(function (mapEmbedFacility) {
            var coords = mapEmbedFacility.dataset.coordinates;
            var iconUrl = mapEmbedFacility.dataset.iconUrl;
            var zoom = mapEmbedFacility.dataset.zoom;
            var popupTitle = mapEmbedFacility.dataset.popupTitle;
            var popupDescription = mapEmbedFacility.dataset.popupDescription;
            if(mapEmbedFacility.dataset.popupDirections) {
                var popupDirections = `<div class="leaflet-popup-content-directions">
                                        <a href="${mapEmbedFacility.dataset.popupDirections}" target="_blank"><i class="fal fa-directions"></i>Get directions</a>
                                        </div>`;
            }
            else var popupDirections = '';
            var coordArray = coords.split(';');

            if (coordArray.length !== 2) {
                return;
            }

            var popupContent = `<div class='leaflet-popup-content-details'>
                                    <h4>${popupTitle}</h4>
                                    <p>${popupDescription}</p>
                                </div>
                                ${popupDirections}
                                `

            var map = L.map(mapEmbedFacility)
            map.setView(coordArray, zoom);

            L.esri.Vector.vectorBasemapLayer('ArcGIS:Topographic', {
                apiKey:
                    'AAPK14c2db245cb5432da99353b71b6433dflik7DtfBuaNdb8nB-MjrF9DiaBOQkhlWuu0asMWlyHxAitjLsCJRPXPBU5blMVGr',
            }).addTo(map);

            var icon = L.icon({
                iconUrl: iconUrl,
                iconSize: [50, 50],
                iconAnchor: [25, 50],
            });

            var marker = L.marker(coordArray, { icon: icon, alt: 'Map marker pin' , }).addTo(map);
            if(document.getElementsByClassName("leaflet-marker-icon")[0]){
                document.getElementsByClassName("leaflet-marker-icon")[0].removeAttribute('tabindex')
            }
           
            var popup = L.popup({
                    className:'qhealth_facility_tooltip',
                    closeButton:false,
                    closeOnClick:false,
                    keepInView:true,
                    autoClose:false,
                    offset: [180, 100]
                })
                .setLatLng(coordArray)
                .setContent(popupContent)
                .openOn(map);
        });
    }

    QH.facilityMap = facilityMap;    
    document.addEventListener('DOMContentLoaded', QH.facilityMap.init);
})();
