(function(QH) {
    "use strict";
    
    /**
     * The main namespace for all QHDS functions and variables
     * @module _global
     */
    var QH = QH ? QH : {};
    QH.utils = {
        /**
         * Add 'js' body class if JS is enabled on the page
         * 
         * @memberof module:_global
         */
        'browserJS': function() {
            document.querySelector("html").classList.remove("no-js");
            document.querySelector("html").classList.add("js");
        },
        
        /**
        * Returns a function, that, as long as it continues to be invoked, will not 
        * be triggered. The function will be called after it stops being called for 
        * N milliseconds. If `immediate` is passed, trigger the function on the 
        * leading edge, instead of the trailing.
        * https://davidwalsh.name/javascript-debounce-function
        * 
        * @memberof module:_global
        * 
        * @param {function} func 
        * @param {number} wait 
        * @param {boolean} immediate 
        * 
        * @returns {function}
        */
        'debounce': function (func, wait, immediate) {
            var timeout;
            return function () {
                var context = this,
                    args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },

        /**
         * Convert nodeList to Array (for IE11)
         * 
         * @memberof module:_global
         * 
         * @param {*} node_list 
         * 
         * @returns {Node[]}
         */
        'listToArray': function(node_list) {
            return Array.prototype.slice.call(node_list);
        },

        /**
         * Set browser cookie
         * 
         * @memberof module:_global
         * 
         * @param {string} cookie_name 
         * @param {string} cookie_value 
         */
        'setCookie': function(cookie_name, cookie_value) {
            var cookie_entry = cookie_name + '=' + encodeURIComponent(cookie_value) + ';';
            document.cookie = cookie_entry + '; path=/;';
        },
        /**
         * Get browser cookie
         * 
         * @memberof module:_global
         * 
         * @param {string} cookie_name 
         * 
         * @returns {c_value}
         */
        'getCookie': function(cookie_name) {
            var c_value = " " + document.cookie;
            var c_start = c_value.indexOf(" " + cookie_name + "=");
            if (c_start == -1) {
                c_value = null;
            }
            else {
                c_start = c_value.indexOf("=", c_start) + 1;
                var c_end = c_value.indexOf(";", c_start);
                if (c_end == -1) {
                    c_end = c_value.length;
                }
                c_value = unescape(c_value.substring(c_start,c_end));
            }
            return c_value;
        },
        /**
         * Get users location
         * 
         * @memberof module:_global
         * 
         */
        'getUsersLocation': function(override) {
            
            var userLocationCookie = QH.utils.getCookie('qh_user_location');
            if(userLocationCookie !== null && !override) {
                return JSON.parse(userLocationCookie);
            }

            if (navigator.geolocation) {

                navigator.geolocation.getCurrentPosition(function(position) {
                    var location = {
                        "latitude": position.coords.latitude,
                        "longitude": position.coords.longitude,
                        "isGeolocated": true,
                        "location": '',
                        "hhs_id": 'hhs-1',
                        "hhs_name": 'HHS 1'
                    };
                    QH.utils.setCookie('qh_user_location', JSON.stringify(location));
                    setTimeout(function() {
                        window.location.reload(false);
                    }, 300);

                    return `${position.coords.latitude},${position.coords.longitude}`;
                }, function(error) {
                    console.log(error);

                    return false;
                }, { timeout: 10000 });

            } else {
                console.log('Unable to locate');
                return false;
            }
        },
        /**
         * Update users location
         * 
         * @memberof module:_global
         * 
         */
        'updateUsersLocation': function(lat, lng, location) {
            var userLocationCookie = QH.utils.getCookie('qh_user_location');
            var userLocationData = {};

            if(userLocationCookie !== null) {
                userLocationData = JSON.parse(userLocationCookie);
            }

            if(Object.keys(userLocationData).length > 0) {
                userLocationData.latitude = lat;
                userLocationData.longitude = lng;
                userLocationData.isGeolocated = false;
                userLocationData.location = location;

                QH.utils.setCookie('qh_user_location', JSON.stringify(userLocationData));
                setTimeout(function() {
                    window.location.reload(false);
                }, 300);
            }
        },
        /**
         * Update users location
         * 
         * @memberof module:_global
         * 
         */
        'isGeolocated': function() {
            var userLocationCookie = QH.utils.getCookie('qh_user_location');
            var userLocationData = {};

            if(userLocationCookie !== null) {
                userLocationData = JSON.parse(userLocationCookie);
            }

            if(Object.keys(userLocationData).length > 0) {

                return userLocationData.isGeolocated;
            }

            return false;
        }
    };

    // Make QH variable available to the console for debugging
    window.QH = QH;

    QH.utils.browserJS();
    

})(window.QH);