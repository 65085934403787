/**
 * @module adminForm
 */

import 'select2';
import 'select2/dist/css/select2.css';

/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';

/* A theme is also required */
import 'tinymce/themes/silver';

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';

/* jQuery Sortable */
import 'jquery-ui/ui/widgets/sortable';

(function () {

    var adminForm = {};
    adminForm.editing = false;

    /**
     * Init the Service Finder admin form. This includes adding
     * event listeners, as well as initializing the tinyMCE Plugin
     * on Appropriate TextAreas 
     * 
     * @memberof module:adminForm
     */
    adminForm.init = function() {

        // Init WYWSIWYG
        tinymce.init({
            selector: '.tinymce',
            menubar: false,
            plugins: 'advlist code link lists table paste',
            toolbar: 'undo redo | paste | formatselect | bold italic | bullist numlist | link code | help',
            paste_preprocess: function(plugin, args) {
                console.log(args.content);
                // args.content += ' preprocess';
            },
            paste_postprocess: function(plugin, args) {
                console.log(args);

                // select all elements. Or just specific ones.
                var elements = args.node.getElementsByTagName("*");

                // use dataset api to delete all properties.
                for (var i = 0; i < elements.length; i++) {
                    for (var prop in elements[i].dataset) {
                        delete elements[i].dataset[prop];
                    }
                }
            },
            setup: function(editor) {
                editor.on('change', function(e) {
                    editor.save();
                    $('#' + e.target.id).valid();
                });
            }
        });

        // Init jQuery validate
        QH.forms.init();

        // Get auth token for JWT
        serviceFinderData.jwt.getAuthToken();

        // Update 'editing' var
        var $form = $('.qhealth__service_finder_admin_form form');
        var formType = $form.data('type');
        if (formType === 'edit') {
            adminForm.editing = true;
        }

        // Add extra validation
        adminForm.addValidationRules();

        // Populate from query param
        adminForm.populateFromParams();

        // Init Select2 for fields with more than 2 options
        $form.find('select').each(function() {
            var $select = $(this);
            if ($select.children().length > 2) {
                $select.select2();
            }
        });

        // Init autopopulate
        adminForm.addAutoPopulateListeners();

        // Handle Submit
        if (formType === 'top-services') {
            $form.on('submit', adminForm.addTopService);
        } else {
            $form.on('submit', adminForm.handleSubmit);
        }
        
    };

    /**
     * Add custom jQuery validate rules to the form
     * 
     * @memberof module:adminForm
     */
    adminForm.addValidationRules = function() {
         
        var $form = $('.qhealth__service_finder_admin_form form');
        var collection = $form.data('collection').replace(/_/g, '-');
        
        // Add Unique ID validation (with jQuery validate)
        var checkIdUrl = serviceFinderData.baseURL + '/' + collection;
        $('#sf-field__id').rules("add", {
            remote: {
                url: checkIdUrl,
                type: 'get',
                data: {
                    filters: function() {
                        return 'document.id===' + $('#sf-field__id').val();
                    }
                },
                dataFilter: function(data) {
                    var response = JSON.parse(data);
                    if (response.length > 0) {
                        return false;
                    }
                    return true;
                }
            },
            nospaces: true,
            messages: {
                required: $.validator.format("Please enter a unique ID/Web Path"),
                remote: $.validator.format("ID already taken"),
                nospaces: $.validator.format("ID must have no spaces")
            }
        });

        // Check for existing top service (with jQuery validate)
        if ($('#sf-field__top-service').length > 0) {
            var topServiceUrl = serviceFinderData.baseURL + '/services';
            $('#sf-field__top-service').rules("add", {
                remote: {
                    url: topServiceUrl,
                    type: 'get',
                    data: {
                        filters: function() {
                            return 'document.top_in_' + collection + '@>' + $('#sf-field__top-service').data('document');
                        }
                    },
                    dataFilter: function(data) {
                        var response = JSON.parse(data);
                        var selectedValue = $('#sf-field__top-service').val();
                        console.log(response);
                        if (response.length > 0) {
                            var matches = response.filter((service) => {
                                return service.id === selectedValue;
                            });
                            if (matches.length > 0) return false;
                        }
                        return true;
                    }
                },
                messages: {
                    required: $.validator.format("Please choose a service"),
                    remote: $.validator.format("This is already a top service")
                }
            });

        }
        

        // Check for existing hhs service (with jQuery validate)
        if ($('#sf-field__hhs_id[data-hhs-check="true"]').length > 0) {
            var checkHhsServiceUrl = serviceFinderData.baseURL + '/hhs-services';
            $('#sf-field__hhs_id[data-hhs-check="true"]').rules("add", {
                remote: {
                    url: checkHhsServiceUrl,
                    type: 'get',
                    data: {
                        filters: function() {
                            return 'document.service_id===' + $('#sf-field__service_id').val() + ';document.hhs_id===' + $('#sf-field__hhs_id').val();
                        }
                    },
                    dataFilter: function(data) {
                        var response = JSON.parse(data);
                        console.log(response);
                        if (response.length > 0) {
                            return false;
                        }
                        return true;
                    }
                },
                messages: {
                    required: $.validator.format("Please choose a HHS"),
                    remote: $.validator.format("This HHS already has content"),
                }
            });
        }

        // Check for existing service location (with jQuery validate)
        if ($('form[data-collection="service-locations"]').length > 0) {
            var checkServiceLocationURL = serviceFinderData.baseURL + '/service-locations';
            $('#sf-field__service_id').rules("add", {
                remote: {
                    url: checkServiceLocationURL,
                    type: 'get',
                    data: {
                        filters: function() {
                            return 'document.service_id===' + $('#sf-field__service_id').val() + ';document.facility_id===' + $('#sf-field__facility_id').val();
                        }
                    },
                    dataFilter: function(data) {
                        var response = JSON.parse(data);
                        console.log(response);
                        if (response.length > 0) {
                            return false;
                        }
                        return true;
                    }
                },
                messages: {
                    required: $.validator.format("Please choose a service"),
                    remote: $.validator.format("A service location for this service/facility already exists"),
                }
            });

            $('#sf-field__facility_id').on('change', function() {
                $('#sf-field__service_id').valid();
            });
        }
    };
    

    /**
     * Add event listeners for autopopulated fields
     * 
     * @memberof module:adminForm
     */
    adminForm.addAutoPopulateListeners = function() {

        // For each input 
        $('input[data-populated-by]').each(function() {
            var $targetInput = $(this);
            var populateType = $targetInput.data('populate-type');

            // Only add listener if not disabled
            if ($targetInput.attr('disabled') !== 'disabled') {
                var dependantInputKeys = $targetInput.data('populated-by').split(',');
                
                // Add data attributes to dependant fields
                dependantInputKeys.forEach((key, index) => {
                    $('#sf-field__' + key).attr('data-populate', $targetInput.attr('id'));

                    if (populateType === 'concat') {
                        $('#sf-field__' + key).attr('data-populate-index', index);
                    }
                });
                
                // Add listeners to dependant fields
                var $dependantInputs = $('[data-populate="' + $targetInput.attr('id') + '"]');
                
                switch (populateType) {
                    case 'url_transform':
                        $dependantInputs.on('keyup', adminForm.autoPopulate);
                        break;
                    default:
                        $dependantInputs.on('change', adminForm.autoPopulate);
                }

            }
        });
    }

    /**
     * Automatically populate value of target field based
     * on values set in dependant field(s)
     * 
     * @memberof module:adminForm
     */
    adminForm.autoPopulate = function() {
        var $field = $(this);
        var targetId = $field.data('populate');
        var $targetInput = $('#' + targetId);
        var populateType = $targetInput.data('populate-type');

        // Update target value depending on type
        switch (populateType) {

            case 'url_transform':
                $targetInput.val(adminForm.kebabFormat($field.val()));
                break;

            case 'concat':
                var useFriendlyNames = $targetInput.data('populate-friendly') === true ? true : false;
                var $dependantFields = $('[data-populate="' + targetId + '"]');
                var value = ""
                
                for (var i = 0; i < $dependantFields.length; i++) {
                    if (i > 0) {
                        if (useFriendlyNames) {
                            value += " - ";
                        } else {
                            value += "-";
                        }
                    }
                    var $dependantField = $('[data-populate="' + targetId + '"][data-populate-index="' + i + '"');
                    if (useFriendlyNames) {
                        var partialValue = $dependantField.children(':selected').data('friendly')
                        value += (partialValue ? partialValue : '');
                    } else {
                        value += $dependantField.val();
                    }
                }

                $targetInput.val(value);
                break;

            case 'selected_text':
                $targetInput.val($field.children(':selected').data('friendly'));
                break;
        }

        // Check if new value is valid
        $targetInput.valid();

        // If target field is also dependant, trigger the 'keyup' event
        if (typeof($targetInput.data('populate')) !== 'undefined') {
            $targetInput.trigger('keyup');
        }
    };

    /**
     * Returns kebab-ified version of string
     * 
     * @memberof module:adminForm
     * 
     * @param {string} str 
     * @returns {string}
     * 
     * @example
     * // returns 'service-123'
     * adminForm.kebabFormat('Service 123);
     */
    adminForm.kebabFormat = function(str) {
        return str
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/[^a-zA-Z0-9\s_]/g, "")
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
    };

    /**
     * Handle submit of Service Finder admin form, and
     * add new item to Datastore
     * 
     * @memberof module:adminForm
     * 
     * @param {Document.event} e 
     */
    adminForm.handleSubmit = function(e) {
        e.preventDefault();
        var $form = $(this);
        adminForm.toggleError(false);

        // Save all WYSIWYGs
        $('.tinymce').each(function() {
            var id = $(this).attr('id');
            tinymce.get(id).save();   
        });

        // Check validity
        var isValid = $form.valid();

        // Only proceed if all form input is valid
        if (isValid) {
            var collection = $form.data('collection').replace(/_/g, '-');
            var parent = $form.closest('.qhealth__service_finder_admin_form');
            parent.addClass('loading');
            
            // Get document data from form
            var docData = {};
            $form.find('input[id^=sf-field__], textarea[id^=sf-field__], select[id^=sf-field__]').each(function() {
                var type = $(this).attr('name').split('|')[0];
                var key = $(this).attr('name').split('|')[1];
                var value = $(this).val();

                switch (type) {
                    case 'wysiwyg':
                        var value = tinymce.get('sf-field__' + key).getContent();
                        docData[key] = value;
                        break;
                    case 'number':
                        docData[key] = Number(value);
                        break;
                    default:
                        docData[key] = value;
                }
            });

            // Get data from JWT
            docData.updatedBy = serviceFinderData.jwt.getPayload().fullName;
            docData.updatedDate = Date.now();

            // If editing, update the document
            if (adminForm.editing) {
                serviceFinderData.collection(collection).doc(docData.id).update(docData).then(() => {
                    console.log('Doc updated');
                    adminForm.redirectToDocument($form, docData.id);
                }).catch((error) => {
                    console.log('Failed to update document');
                    console.error(error);
                    adminForm.toggleError(true);
                    parent.removeClass('loading');
                });
            
            // If creating, add new document
            } else {
                serviceFinderData.collection(collection).add(docData, docData.id).then(() => {
                    console.log('Doc added');
                    adminForm.redirectToDocument($form, docData.id);
                }).catch((error) => {
                    console.log('Failed to add document');
                    console.error(error);
                    adminForm.toggleError(true);
                    parent.removeClass('loading');
                });
            }
        }
    };

    /**
     * Toggle the main admin form error
     * 
     * @memberof module:adminForm
     * 
     * @param {boolean} showError 
     */
    adminForm.toggleError = function(showError) {
        if (showError) {
            $('#sf-admin-form__error').addClass('active');
        } else {
            $('#sf-admin-form__error').removeClass('active');
        }
    }

    /**
     * Redirect to new/edited document
     * 
     * @memberof module:adminForm
     * 
     * @param {jQuery} $form 
     * @param {string} docId 
     */
    adminForm.redirectToDocument = function($form, docId) {
        var collection = $form.data('collection').replace(/_/g, '-');
        var detailsURL = $form.attr('action') + '?collection=' + collection + '&document=' + docId;
        console.log('redirecting to ' + detailsURL);
        window.location = detailsURL;
    }

    /**
     * Handle addition of new top service
     * 
     * @memberof module:adminForm
     * 
     * @param {Document.event} e 
     */
     adminForm.addTopService = function(e) {
        e.preventDefault();
        var $form = $(this);
        var isValid = $form.valid();

        if (isValid) {
            console.log('adding top service');
            var parent = $form.closest('.qhealth__service_finder_admin_form');
            var collection = $form.data('collection').replace(/_/g, '-');
            var document = $('#sf-field__top-service').data('document');
            var selectedService = $('#sf-field__top-service').val();
            parent.addClass('loading');

            serviceFinderData.jwt.getAuthToken();

            serviceFinderData.collection('services').doc(selectedService).get().then((service) => {
                service['top_in_' + collection].push(document);
                service.updatedBy = serviceFinderData.jwt.getPayload().fullName;
                service.updatedDate = Date.now();
                console.log('updated service', service);

                serviceFinderData.collection('services').doc(selectedService).update(service).then(() => {
                    parent.removeClass('loading');
                    parent.html('<p>Top service added</p>');
                    console.log('Top service added');
                })
            });
        }
    };

    /**
     * Populate fields based on query params
     * Eg. ?service_id=service-1 will populate 
     * #sf-field__service-id with "service-1"
     * 
     * @memberof module:adminForm
     */
    adminForm.populateFromParams = function() {
        var search = window.location.search.substring(1);
        var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        for (var key in params) {
            var value = params[key];
            $('#sf-field__' + key).val(value);
        }
    };

    // Make admin form public
    QH.adminForm = adminForm;

    // Init Admin Form
    $(document).ready(function() {
        if ($('.qhealth__service_finder_admin_form form').length > 0) {
            QH.adminForm.init();
        }
    });
    
}());