(function () {
    'use strict';

    var newsArticleReadTime = {};
    var copyToClipBoard = {};
    var newsArticle = $('.qhealth__news_article');


    newsArticleReadTime.init = function() {
        let readTimeEl = newsArticle.find('.qhealth__news_article__read-time');
        let articleBody = newsArticle.find('.qhealth__news_article__body');
        let articleHeading = newsArticle.find('.qhealth__news_article__heading');
        let wordCount = articleHeading.text().trim().split(' ').length + articleBody.text().trim().split(' ').length;
        let wpm = 200;
        let readTime = Math.ceil(wordCount / wpm);

        readTimeEl.text(`Read time: ${readTime} ${readTime > 1 ? 'minutes' : 'minute'}`);
    };

    copyToClipBoard.init = function(text, $el) {

        if (navigator.clipboard) { // All popular browsers
            navigator.clipboard.writeText(text).then(function () {
                if($el) {
                    $el.find('.qhealth__copied-to-clipboard').toggleClass('active').text('Copied').fadeOut(1400, function() {
                        $(this).empty().show().toggleClass('active');
                    });
                } else {
                    $('.qhealth__copied-to-clipboard').toggleClass('active').text('Copied').fadeOut(1400, function() {
                        $(this).empty().show().toggleClass('active');
                    });
                }
            }, function (err) {
                console.log('Clipboad API not available, please upgrade your browser.')
            });
        } else if(window.clipboardData) { // IE11
            window.clipboardData.setData("Text", text);
        }
    }

    QH.newsArticleReadTime = newsArticleReadTime;
    QH.copyToClipBoard = copyToClipBoard;


    $(function() {

        if(newsArticle.length > 0) {
            QH.newsArticleReadTime.init();

            $('.qhealth__news_article__share-btn').each(function() {
                QH.Modal.init(this);
            });
    

            $('.qhealth__news_article__copy-link').on('click', function() {
                QH.copyToClipBoard.init($(this).find('span').text(), $(this));
            });


            $('.qhealth__news_article__copy-link span[tabindex="0"]').keypress(function(e) {
                var keycode = (e.keyCode ? e.keyCode : e.which);

                if(keycode == '32' || keycode == '13') {
                    e.preventDefault();
                    QH.copyToClipBoard.init($(this).find('span').text()); 
                }
            });
        }
    });
    
}());