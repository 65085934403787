(function () {
    'use strict';
    window.addEventListener('DOMContentLoaded', function () {
        var $expandAllFacets = $('.qhealth__service_search__facets__expand-all');
        var allExpended = false;
        var $facetBtns = $('.js-qhealth__accordion');

        $expandAllFacets.on('click', () => {
            let $accordions = $expandAllFacets.closest('.qhealth__accordion__body').find('.js-qhealth__accordion');

            if(!allExpended) {
                $accordions.each(function() {
                    let $this = $(this);

                    $this.removeClass('qhealth__accordion--closed').addClass('qhealth__accordion--open');
                    $this.next().removeClass('qhealth__accordion--closed').addClass('qhealth__accordion--open');
                });

                $expandAllFacets.text('Collapse all');
                allExpended = true;
            } else {
                $accordions.each(function() {
                    let $this = $(this);

                    $this.removeClass('qhealth__accordion--open').addClass('qhealth__accordion--closed');
                    $this.next().removeClass('qhealth__accordion--open').addClass('qhealth__accordion--closed');
                }); 

                $expandAllFacets.text('Expand all');
                allExpended = false;
            }
        });

        $facetBtns.on('click', () => {
            let expandedCounter = 0;
            $facetBtns.each(function(i, obj) {
                if($(this).hasClass('qhealth__accordion--open')) {
                    expandedCounter++;
                }
            });

            if(expandedCounter > 0) {
                $expandAllFacets.text('Collapse all');
                allExpended = true;
            } else if(expandedCounter === 0) {
                $expandAllFacets.text('Expand all');
                allExpended = false;
            }
        });

        let serviceCategoriesUrl = "https://au-a14fc409.datastore.squiz.cloud/service-categories";
        let hhsUrl = "https://au-a14fc409.datastore.squiz.cloud/hhs";

        $.get(serviceCategoriesUrl, function(data) {
            let serviceCats = data;
            
            $('.qhealth__service_search__facets__section__item li a').each(function() {
                let $this = $(this);

                for(let i = 0; i < serviceCats.length; i++) {
                    if(serviceCats[i].id === $this.text()) {
                        $this.text(serviceCats[i].name);
                    }
                }
            });
        });

        $.get(hhsUrl, function(data) {
            let serviceHhs = data;
            
            $('.qhealth__service_search__facets__section__item li a').each(function() {
                let $this = $(this);

                for(let i = 0; i < serviceHhs.length; i++) {
                    if(serviceHhs[i].id === $this.text()) {
                        $this.text(serviceHhs[i].name);
                    }
                }
            });
        });
    });
}());