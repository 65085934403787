(function () {
    'use strict';
    window.addEventListener('DOMContentLoaded', function () {

        // Temp Map Example Code
        var mapContainer = document.getElementById('qhealth__service_search__result__map');

        if(mapContainer) {
            var serviceSearchResultMap = L.map('qhealth__service_search__result__map').setView([-22.5752, 144.0848], 4);

            $("#qh-search-tab--map .qhealth__service_search__results__list li").each(function() {
                if($(this).data('facilitylatlng')) {
                    L.marker($(this).data('facilitylatlng')).addTo(serviceSearchResultMap);
                }
            });

            L.esri.Vector.vectorBasemapLayer('ArcGIS:Topographic', {
                apiKey:
                    'AAPK14c2db245cb5432da99353b71b6433dflik7DtfBuaNdb8nB-MjrF9DiaBOQkhlWuu0asMWlyHxAitjLsCJRPXPBU5blMVGr',
            }).addTo(serviceSearchResultMap);
        }
    });
}());