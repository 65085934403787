(function () {


    'use strict';

    
    var topServicesModal = {};
    var userLocationFinder = {};

    /**
     * Initialise modal funtionality
     * 
     * @memberof module:servicesModal
     */
    topServicesModal.init = function() {
        var modals = document.querySelectorAll('.qhealth__modal');
        var modalUnderlay = document.querySelectorAll('.qhealth__modal__underlay');
        var modalBtn = document.querySelectorAll('.qhealth__open-modal');
        var getLocationBtn = document.querySelectorAll('.qhealth__geo-location__request');
        var locationDenyBtn = document.querySelectorAll('.qhealth__geo-location__deny ');

        if(modalBtn) {
            // loop over each button found that opens a modal and setup modals
            for (let i = 0; i < modalBtn.length; i++) {
                modalBtn[i].addEventListener('click', function() {
                    let modalOpenBtn = this;
                    let modalTarget = this.getAttribute('data-modaltarget');
                    let modalEl = document.querySelector(`#${modalTarget}`);

                    if(modalEl) {
                        let modalCloseBtn = modalEl ? modalEl.querySelector('.qhealth__modal__close') : null;
                        let modalDenyBtn = modalEl ? modalEl.querySelector('.qhealth__modal__deny') : null;
                        let modalAcceptBtn = modalEl ? modalEl.querySelector('.qhealth__modal__accept') : null;
                        // trap focus
                        // add all the elements inside modal that are focusable
                        let focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
                        let firstFocusableElement = modalEl.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
                        let focusableContent = modalEl.querySelectorAll(focusableElements);
                        let lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

                        document.addEventListener('keydown', function(e) {
                            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                            if (!isTabPressed) {
                                return;
                            }

                            if (e.shiftKey) { // if shift key pressed for shift + tab combination
                                if (document.activeElement === firstFocusableElement) {
                                    lastFocusableElement.focus(); // add focus for the last focusable element
                                    e.preventDefault();
                                }
                            } else { // if tab key is pressed
                                if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                                    firstFocusableElement.focus(); // add focus for the first focusable element
                                    e.preventDefault();
                                }
                            }
                        });

                        modalEl.classList.add('active');
                        modalEl.focus();     
                        
                        // if modal is update-location then initialise userLocationFinder
                        if(modalEl.classList.contains('qhealth__services-modal__update-location')) {
                            QH.userLocationFinder.init();
                        }

                        if(modalCloseBtn) {
                            modalCloseBtn.addEventListener('click', function(e) {
                                modalEl.classList.remove('active');
                                modalOpenBtn.focus();
                            });
                        }

                        if(modalDenyBtn) {
                            modalDenyBtn.addEventListener('click', function(e) {
                                modalEl.classList.remove('active');
                                modalOpenBtn.focus();
                            });
                        }

                        if(modalAcceptBtn) {
                            modalAcceptBtn.addEventListener('click', function(e) {
                                modalEl.classList.remove('active');
                                modalOpenBtn.focus();
                            });
                        }
                    } else {
                        console.log(`Modal '#${modalTarget}' not found.`)
                    }
                });

                
            }
        }

        if(modalUnderlay) {
            for (let i = 0; i < modalUnderlay.length; i++) {
                modalUnderlay[i].addEventListener('click', function() {
                    this.parentNode.classList.remove('active');
                });
            }
        }
    
        if(getLocationBtn) {
            for (let i = 0; i < getLocationBtn.length; i++) {

                getLocationBtn[i].addEventListener('click', function() {
                    QH.utils.getUsersLocation();
                });
            }
        }
    
        if(locationDenyBtn) {
            for (let i = 0; i < locationDenyBtn.length; i++) {
                locationDenyBtn[i].addEventListener('click', function() {
                    QH.utils.setCookie('qh_geolocation', 'deny')
                });
            }
        }

        if(modals && QH.utils.getCookie('qh_geolocation') !== 'deny') {
            for (let i = 0; i < modals.length; i++) {
                if(modals[i].classList.contains('qhealth__services-modal')) {
                    var modalId = modals[i].id;
                    var modalToActivate = document.querySelector(`[data-modaltarget=${modalId}]`);

                    console.log(modalId);

                    if(!QH.utils.getCookie('qh_user_location')) {
                        if(modalToActivate) {
                            modalToActivate.click();
                        }
                    }
                }
            }
        }
    
    }
    /**
     * Function used for the 'Change location' modal
     * 
     * @memberof module:servicesModal
     */
    userLocationFinder.init = function() {


        // Need to move this stuff into a central location as it's also used for BSQ location finder
        var $ = require('jquery');
        var Bloodhound = require('corejs-typeahead/dist/bloodhound.js');
        var datumLocationData;
        require('corejs-typeahead/dist/typeahead.jquery.js');

        var addressPacket = new Bloodhound({
            datumTokenizer: function(d) {
                var locationTokens = Bloodhound.tokenizers.whitespace(d.location);
                var postcodeTokens = Bloodhound.tokenizers.whitespace(d.postcode);

                return locationTokens.concat(postcodeTokens);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            limit: 20,
            // remote: {
            //   url: 'https://qhscb.squiz.cloud/core/_designs/connectors/rest/get-auspost-data',
            //   prepare: function (query, settings) {
            //     settings.url = encodeURI(settings.url + '?query=' + query);
            //     return settings;
            //   }
            // }
            local: [
                        {
                            "category": "Post Office Boxes",
                            "id": 8535,
                            "location": "BRISBANE",
                            "postcode": 4001,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8530,
                            "latitude": -27.466785,
                            "location": "BRISBANE ADELAIDE STREET",
                            "longitude": 153.027153,
                            "postcode": 4000,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8549,
                            "latitude": -27.38837225,
                            "location": "BRISBANE AIRPORT",
                            "longitude": 153.1132012,
                            "postcode": 4008,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8531,
                            "latitude": -27.46977074,
                            "location": "BRISBANE CITY",
                            "longitude": 153.0251227,
                            "postcode": 4000,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8532,
                            "latitude": -27.467288,
                            "location": "BRISBANE GPO",
                            "longitude": 153.028629,
                            "postcode": 4000,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8709,
                            "latitude": -27.533576,
                            "location": "BRISBANE MARKET",
                            "longitude": 152.9993058,
                            "postcode": 4106,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8822,
                            "latitude": -27.48334153,
                            "location": "EAST BRISBANE",
                            "longitude": 153.0467209,
                            "postcode": 4169,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8836,
                            "latitude": -27.39000408,
                            "location": "PORT OF BRISBANE",
                            "longitude": 153.1782025,
                            "postcode": 4178,
                            "state": "QLD"
                        },
                        {
                            "category": "Post Office Boxes",
                            "id": 8596,
                            "location": "ROYAL BRISBANE HOSPITAL",
                            "postcode": 4029,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8697,
                            "latitude": -27.47806509,
                            "location": "SOUTH BRISBANE",
                            "longitude": 153.0193998,
                            "postcode": 4101,
                            "state": "QLD"
                        },
                        {
                            "category": "Delivery Area",
                            "id": 8698,
                            "latitude": -19.7061005,
                            "location": "SOUTH BRISBANE BC",
                            "longitude": 145.7740021,
                            "postcode": 4101,
                            "state": "QLD"
                        }
                    ]
        });

        
        var $updateLocationInput =  $(".qhealth__modal .qhealth__update-location-input");

        if(!QH.utils.isGeolocated()) {
            $updateLocationInput.val(QH.utils.getUsersLocation().location);
        }

        $updateLocationInput.typeahead('destroy');

        $updateLocationInput.typeahead(null, {
            name: 'addresses',
            minLength: 3,
            display: 'location',
            limit: 10,
            source: addressPacket,
            templates: {
                suggestion: function(data) {
                    return `<p><strong>${data.location}</strong>, ${data.postcode}</p>`;
                }
            }
        }).on('typeahead:select', function (obj, datum, name) {
            if(datum.latitude && datum.longitude) {
                datumLocationData = datum;
            }
        });

        var $useCurrentLocationBtn = $('.qhealth__use-current-location-btn');

        if($useCurrentLocationBtn.length > 0) {
            if(QH.utils.isGeolocated()) {
                $useCurrentLocationBtn.addClass('active').attr('disabled', true);
            }

            $useCurrentLocationBtn.on('click', function() {
                $useCurrentLocationBtn.addClass('active');
                QH.utils.getUsersLocation(true);
            });
        }


        $(document).on('click', '.qhealth__update-location-btn', function() {
            if(datumLocationData) {
                let qhUserLocation = QH.utils.getCookie('qh_user_location');

                if(qhUserLocation) {
                    QH.utils.updateUsersLocation(datumLocationData.latitude, datumLocationData.longitude, datumLocationData.location);
                    $useCurrentLocationBtn.removeClass('active').attr('disabled', false);
                }
            } else {
                console.log('Location not found in search field.');
            }
        });
    }

    QH.topServicesModal = topServicesModal;
    QH.userLocationFinder = userLocationFinder;

    window.addEventListener('DOMContentLoaded', function () {
        QH.topServicesModal.init();
    });

    
}());