(function () {
    'use strict';

    /**
     * @module adminList
     */
    var adminList = {};

    // Vars
    adminList.tables = [];
    adminList.docToDelete = {};

    /**
     * Initialise the admin list
     * 
     * @memberof module:adminList
     */
     adminList.init = function() {

        // Init each table
        $('.datatable').each(function() {
            adminList.tables.push($(this).DataTable());
        })
        
        // Add delete callback to QH.Modal
        QH.Modal.callbackFns.deleteDocument = adminList.deleteDocument;

        // Add event listeners for Delete btns
        adminList.addDeleteListeners();
        
        // Re-add delete listeners when Datatable is re-drawn
        adminList.tables.forEach(function(table) {
            table.on( 'draw.dt', function () {
                adminList.addDeleteListeners();
            });
        })
    }

    /**
     * Add event listeners to all delete buttons in the adminList
     * 
     * @memberof module:adminList
     */
    adminList.addDeleteListeners = function() {
        $('.qhealth__admin--delete').off();

        $('.qhealth__admin--delete').each(function() {
            QH.Modal.init(this);
        });

        $('.qhealth__admin--delete').on('click', function(e) {
            var $button = $(this);
            
            adminList.docToDelete = {
                'collection': $button.data('collection'),
                'document': $button.data('document'),
                'top_service': $button.data('top-service')
            };
        });
    }

    /**
     * Delete a document from Datastore (and the list)
     * 
     * @memberof module:adminList
     * 
     * @param {Document.event} e 
     */
    adminList.deleteDocument = function(e) {
        var collection = adminList.docToDelete.collection;
        var document = adminList.docToDelete.document;

        serviceFinderData.collection(collection).doc(document).delete().then(() => {
            console.log('Doc deleted');

            // Look for row in each of the tables, and remove if it exists
            adminList.tables.forEach(function(table) {
                var row = table.row($('tr[data-collection="' + collection + '"][data-document="' + document + '"]'));
                if (row.length > 0) {
                    row.remove().draw();
                }
            });
        });
    }

    QH.adminList = adminList;    

    $(document).ready(function() {
        if ($('.qhealth__service-finder-admin__list').length > 0) {
            QH.adminList.init();
        }
    });
})();
